import * as React from "react"
import { Helmet } from "react-helmet";
import { Link } from "gatsby"
import { Resizable } from "re-resizable";
import logo from "./../../images/logo.svg";

import ogImage from "./../../images/og-image.jpg";

const PreviewApplicationUI = () => {

    const [width, setWidth] = React.useState(540);
    const [height, setHeight] = React.useState(720);
    const [page, setPage] = React.useState(null);
    const [responsive, setResponsive] = React.useState(false);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setPage(params.get("page"));
    }, []);

    return (
        <div id="overview">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tailwind CSS Application UI Preview - Flowbite</title>
                <link rel="canonical" href="https://flowbite.com/application-ui/preview/" />
                <meta name="description" content="Check out the application UI pages built with Tailwind CSS and Flowbite which includes pages like a Kanban board, Calendar, Charts, and more." />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@bergify_" />
                <meta name="twitter:title" content="Flowbite - Tailwind CSS Components" />
                <meta name="twitter:description" content="Check out the application UI pages built with Tailwind CSS and Flowbite which includes pages like a Kanban board, Calendar, Charts, and more." />
                <meta name="twitter:image" content={'https://flowbite.com' + ogImage} />
                <meta name="twitter:creator" content="@bergify_" />

                <meta property="og:url" content="https://flowbite.com/" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Flowbite - Tailwind CSS Components" />
                <meta property="og:description" content="Check out the application UI pages built with Tailwind CSS and Flowbite which includes pages like a Kanban board, Calendar, Charts, and more." />
                <meta property="og:image" content={'https://flowbite.com' + ogImage} />
            </Helmet>
            <main>
                <div className="p-4 w-full bg-white shadow-md z-10 mb-2 flex justify-between items-center">
                    <span className="font-bold sm:hidden">Application UI</span>
                    <span className="font-normal hidden sm:inline">You're now previewing <span className="font-bold">Application UI</span> from Flowbite Pro.</span>
                    <div className="flex items-center">
                        {/* <button onClick={() => { setResponsive(!responsive) }} className="text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded inline-block focus:outline-none mr-3">
                            <svg class="text-gray-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
                        </button> */}
                        <a href="/pro/#pricing" target="_blank" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3">Buy now</a>
                        {/* <a href={page ? 'https://flowbite.com/application-ui/demo/' + page : 'https://flowbite.com/application-ui/demo/'} target="_blank" data-tippy-content="Preview without frame" className="text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded inline-block">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </a> */}
                        <button onClick={() => { window.close() }} className="text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded inline-block">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>
                </div>
                {responsive ? (
                    <div className="w-full flex justify-center">
                        <div>
                            <div className="flex-none text-center text-xs leading-4 tabular-nums whitespace-pre py-3 text-gray-900 dark:text-gray-400">{width} x {height}</div>
                            <Resizable
                                className="flex justify-center items-center border-4 border-gray-200"
                                size={{ width, height }}
                                onResizeStop={(e, direction, ref, d) => {
                                    setWidth(width + d.width);
                                    setHeight(height + d.height);
                                }}
                            >
                                <iframe src={page ? 'https://flowbite.com/application-ui/demo/' + page : 'https://flowbite.com/application-ui/demo/'} className="w-full h-full" frameBorder="0"></iframe>
                            </Resizable>
                        </div>
                    </div>
                ) : (
                    <iframe src={page ? 'https://flowbite.com/application-ui/demo/' + page : 'https://flowbite.com/application-ui/demo/'} className="w-full" style={{ height: 'calc(100vh - 80px)' }} frameBorder="0"></iframe>
                )}
            </main >
        </div >
    )
}
export default PreviewApplicationUI